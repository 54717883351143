<template>
  <div v-if="$q.screen.width >= 800">
    <hr class="q-my-md q-mx-xs" />
    <q-card-actions class="q-pa-none row items-center justify-between">
      <q-btn
        flat
        icon="logout"
        label="Sair"
        class="col-12 col-md-auto q-mb-sm text-red"
        @click="logout"
      />

      <!-- Espaço condicional para telas maiores -->
      <q-space v-if="$q.screen.gt.sm" />

      <!-- Botão 'Voltar', mostrado somente se `show_previous` for falso -->
      <q-btn
        v-if="showPrevious"
        flat
        type="submit"
        label="Voltar"
        class="col-12 col-md-auto"
        @click="goToPreviousStep"
      />

      <!-- Botão 'Próximo', mostrado se `show_next` for verdadeiro -->
      <q-btn
        v-if="showNext"
        type="submit"
        color="black"
        :label="isLastStep ? 'Finalizar' : 'Próximo'"
        class="col-12 col-md-auto"
        @click="nextStep"
      />
    </q-card-actions>
  </div>

  <!-- Espaço condicional para telas menores -->
  <div v-else>
    <hr class="q-my-md q-mx-xs" />

    <div class="row">
      <div class="col-6">
        <q-btn
          v-if="showPrevious"
          flat
          type="submit"
          label="Voltar"
          class="full-width"
          @click="goToPreviousStep"
        />
      </div>
      <div :class="{ 'col-12': !showPrevious, 'col-6': showPrevious }">
        <q-btn
          v-if="showNext"
          type="submit"
          color="black"
          :label="isLastStep ? 'Finalizar' : 'Próximo'"
          class="full-width"
          @click="nextStep"
        />
      </div>
      <div class="col-12">
        <q-btn
          flat
          icon="logout"
          label="Sair"
          class="col-12 col-md-auto q-mb-sm text-red full-width q-mt-md"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { deleteLocalToken } from "@/modules/auth/storage";
import { defineProps, defineEmits } from "vue";

defineProps({
  showNext: {
    type: Boolean,
    default: true,
  },
  showPrevious: {
    type: Boolean,
    equired: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["next-step", "go-to-previous-step"]);

// Função para emitir o evento 'next-step'
const nextStep = () => emit("next-step");

// Função para emitir o evento 'go-to-previous-step'
const goToPreviousStep = () => emit("go-to-previous-step");

const logout = () => {
  deleteLocalToken();
  window.location.reload();
};
</script>
