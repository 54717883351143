import script from "./FormActions.vue?vue&type=script&setup=true&lang=js"
export * from "./FormActions.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardActions,QBtn,QSpace});
