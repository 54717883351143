<template>
  <q-dialog
    persistent
    v-model="persistent"
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card style="width: 470px" class="dialog-form">
      <div class="text-center q-mt-md">
        <img
          :src="parent.logo_foto_link"
          :alt="parent.nome"
          style="width: 120px"
        />
      </div>
      <!-- Step Progress Bar -->
      <q-card-section style="margin-top: -20px">
        <div
          class="q-mt-md"
          v-if="getParameterizedRegistrationInRevStep"
          v-html="getParameterizedRegistrationInRevStep"
        />
        <div v-else>
          <div
            class="text-h5 text-weight-bold text-center q-pa-md"
            v-html="$t('signup.parameterized_registration.data_pending')"
          />
          <div
            class="text-center q-pr-md q-pl-md"
            v-html="$t('account.perfil_analyzing')"
          />
          <div class="text-center q-mt-lg q-mb-lg">
            <q-spinner-ball color="grey" size="10em" />
          </div>
        </div>
      </q-card-section>
      <div class="q-pa-md">
        <FormActions
          :showNext="false"
          :showPrevious="false"
          @next-step="onNextStep"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script setup>
import { useGetters, useActions } from "vuex-composition-helpers";
import FormActions from "./FormActions.vue";
import { ref, onMounted } from "vue";

const persistent = ref(true);

const { parent, getParameterizedRegistrationInRevStep } = useGetters({
    parent: "partner/getPartner",
    getParameterizedRegistrationInRevStep:
      "account/getParameterizedRegistrationInRevStep",
  }),
  { fetchParameterizedRegistrationParam } = useActions({
    fetchParameterizedRegistrationParam:
      "account/fetchParameterizedRegistrationParam",
  });

onMounted(() => {
  fetchParameterizedRegistrationParam({
    slug: parent.value.slug,
    param: "parameterized_registration",
  });
});
</script>

<style lang="scss">
.input-label {
  font-size: 13px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 2px;
  color: #111928;
  text-transform: uppercase;
}

.dialog-form {
  border-top: 15px solid rgb(206, 206, 206);
}
</style>
