import script from "./FormModalPending.vue?vue&type=script&setup=true&lang=js"
export * from "./FormModalPending.vue?vue&type=script&setup=true&lang=js"

import "./FormModalPending.vue?vue&type=style&index=0&id=006739bf&lang=scss"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSpinnerBall});
